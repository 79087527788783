
import AbpBase from '@/lib/abpbase'
import { Component, Vue, Inject, Prop, Watch } from 'vue-property-decorator'
import Marca from '@/store/entities/OZONE/marca'
import PageRequest from '@/store/entities/page-request'
import { Logger } from 'sass'
import { ConsoleLogger } from '@microsoft/signalr/dist/esm/Utils'
class PageMarcaRequest extends PageRequest {
  nombre: string
  clienteId: number
}
@Component
export default class SelectMarca extends AbpBase {
  @Prop({ type: Object, default: null }) value: Marca
  @Prop({ type: Boolean, default: true }) showPagination: Boolean
  @Prop({ type: Object, default: null }) rules: Object
  @Prop({ type: Number, default: 0 }) clienteId: number
  @Prop({ type: Boolean, default: false }) disabled: Boolean
  @Prop({ type: String, default: "Marca" }) name: Boolean
  marca: Marca = null
  pagerequestMarcas: PageMarcaRequest = new PageMarcaRequest()
  maxResultCountMarca = 50
  skipCountMarca = 0
  get marcaList() {
    
    return this.$store.state.marca.list
  }
  get marcaCount() {
    return this.$store.state.marca.totalCount
  }

  get clienteState() {
    return this.$store.state.cliente.selected
  }

  async created() {
    // await this.getMarcasDropDown().then(() => {
    //   return
    // })
  }

  async shown() {
    await this.getMarcasDropDown()
  }

  @Watch('clienteId')
  setClienteId(value: number) {
    this.getMarcasDropDown()
  }

  @Watch('clienteState')
  setClienteIdByState() {
    debugger
    this.clienteId = this.$store.state.cliente.selected.key;
    
  }

  async getMarcas(params: any) {
    
    this.pagerequestMarcas.nombre = params
    this.maxResultCountMarca = 25000
    this.skipCountMarca = 0
    this.pagerequestMarcas.maxResultCount = this.maxResultCountMarca
    this.pagerequestMarcas.skipCount = this.skipCountMarca
    this.pagerequestMarcas.clienteId = this.clienteId
    await this.$store.dispatch({
      type: 'marca/getAll',
      data: this.pagerequestMarcas
    })
  }

  async nextPage(offset: number) {
    this.pagerequestMarcas.skipCount = offset
    await this.$store.dispatch({
      type: 'marca/getAll',
      data: this.pagerequestMarcas
    })
  }

  async getMarcasDropDown() {
    this.pagerequestMarcas.maxResultCount = 100;
    this.pagerequestMarcas.skipCount = 0;
    this.pagerequestMarcas.clienteId = this.clienteId
    await this.$store.dispatch({
      type: 'marca/getAll',
      data: this.pagerequestMarcas
    })
  }

  marcaRule = {
    marca: {
      // required: { required: true },
      message: this.L('ThisFieldIsRequired', undefined, this.L('Marca')),
      placeholder: this.L('Marca'),
      trigger: 'blur'
    }
  }
}
